.setting-layout-container {
	margin-top: 21px;
}

.setting-layout-title {
	display: flex;
}

.setting-layout-back{
	display: inline-block;
  font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 20px;
}

.setting-layout-menu {
	width: 154px;
	margin-left: 41px;
}

.menu-container{
	width: 154px;
}

.menu-item {
	font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: rgb(153, 153, 153);
  font-size: 14px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.menu-item-selected {
	font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
	font-weight: 699;
	font-size: 16px;
	color: #000;
	margin-top: 15px;
	margin-bottom: 15px;
}