.brand-card {
  width: 341px;
  height: 60px;
  margin: 5px 0;
}

.brand-card-title {
  margin: 0 0 2px 12px;
  font-size: 16px;
  font-weight: 699;
  color: #000;
}

.brand-card-subtitle {
  margin-left: 12px;
  font-size: 14px;
  color: #696969;
}

.brand-card-avatar {
  width: 40px;
  height: 40px;
  margin-left: 15px;
}
