.btn-addcomment {
  width: 196px !important;
  height: 50px !important;
  font-size: 20px !important;
  margin: 45px 0 !important;
}

.brandcollabpublished-comment {
  width: 1336px;
  height: 110px;
  background-color: #f5f5f5 !important;
}
