.confirmpay-dialog {
  width: 758px;
  height: 495px;
  text-align: center;
}

.btn-confirmpay {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.confirmpay-title {
  margin: 51px 0 7px;
  font-size: 36px;
  color: #000;
  font-weight: 699;
}

.confirmpay-subtitle {
  font-size: 24px;
  color: #000;
  font-weight: 599;
}

.confirmpay-underline {
  text-decoration: underline;
}

.confirmpay-avatar {
  width: 62px;
  height: 62px;
  margin: 0 10px;
}

.confirmpay-warning {
  width: 453px;
  margin-bottom: 57px;
  font-size: 20px;
  color: #ff5252;
}
