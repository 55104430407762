.influencer-list-filter-categories {
	margin-bottom: 45px;
}

.influencer-list-filter-platform {
	margin-bottom: 37px;
}

.influencer-list-filter-select-container {
	margin-top: 40px;
}

.influencer-list-filter-select{
  height: 47px !important;
  width: 185px !important;
}