.submittrack-dialog {
  width: 758px;
  height: 495px;
}

.btn-submittrack {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.submittrack-title {
  font-size: 36px;
  color: #000;
  font-weight: 699;
  text-align: center;
}

.submittrack-subtitle {
  margin: 50px;
  font-size: 24px;
  color: #000;
  font-weight: 599;
}

.submittrack-underline {
  text-decoration: underline;
}

.submittrack-avatar {
  width: 62px;
  height: 62px;
  margin: 0 10px;
}
