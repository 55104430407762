.inbox {
  padding: 51px 8px 30px 19px;
}

.inbox-list-wrap {
  width: 420px;
}

.inbox-buttons {
  padding: 20px 36px;
  border-bottom: 1px #999 solid;
  margin-bottom: 20px;
}

.btn-inboxnewmessage {
  width: 348px !important;
  height: 50px !important;
  font-size: 20px !important;
}

.inbox-list {
  width: 420px;
  height: 700px;
	overflow-y: scroll;
}

.inbox-message-panel {
  width: 811px;
  height: 861px;
  background-color: aliceblue;
}

.inbox-message-list {
	width: 811px;
  height: 645px;
	overflow-y: scroll;
}

.inbox-item {
  margin-top: 5px;
	margin-bottom: 5px;
  padding: 10px 10px 10px 10px;
}

.inbox-message-title-container {
  width: 811px;
  height: 59px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.inbox-message-title-name{
  font-weight: 700;
  font-family: "Inter-Bold", "Inter", sans-serif;
  color: var(--Black, #000000);
  font-size: 16px;
}

.inbox-message {
  margin-bottom: 30px;
}

.inbox-send {
	margin-bottom: 20px;
}

.inbox-send-input {
  border-radius: 2px !important;
}

.inbox-send-btn {
  height: 44px;
  width: 85px;
  font-size: 18px !important;
  border-radius: 2px !important;
}