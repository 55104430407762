.collab-chart-legend {
  margin: 25px 16px 85px;
}

.collab-chart-legend-item {
  font-size: 12px;
  color: #000;
  font-weight: 199;
  margin-right: 8px;
}

.collab-chart-palette {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.collab-chart {
  width: 262px !important;
  height: 262px !important;
}
