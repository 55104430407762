.notifications-container {
	margin-right: 66px;
}

.notifications-email-text {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-bottom: 13px;
	text-align: right;
}

.notifications-btn-update {
	height: 42px;
	width: 118px;
	font-size: 20px !important;
	margin-top: 200px !important;
	margin-bottom: 34px !important;
}