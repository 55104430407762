.brandcollablist {
  width: 1327px;
  margin-top: 28px;
}

.brandcollabitem {
  width: 406px;
}

.brandcollabitem-margin {
  margin-right: 54px;
}

.brandcollabitem-line {
  margin-bottom: 40px;
}

.brandcollabitem-title {
  width: 366px;
  height: 47px;
}

.brandcollabitem-card{
  width: 406px;
  height: 527px;
}

.brandcollabitem-card-block{
  width: 406px;
  height: 225px;
}

.brandcollabitem-link {
  font-size: 24px;
  color: #000;
  font-weight: 699;
  word-break:break-all;
}

.brandcollabitem-link-plain {
  margin-right: 10px;
  font-size: 14px;
  color: #696969;
  word-break:break-all;
}

.btn-brandcollabitem {
  width: 272px !important;
  height: 40px !important;
  margin-bottom: 12px !important;
  font-size: 18px !important;
}

.brandcollabitem-data {
  font-size: 20px;
  color: #000;
}

.brandcollabitem-statistics {
  width: 200px;
  margin-right: 41px;
  font-size: 48px;
  font-weight: 699;
}
