.home-bg-fashion{
	background-image: url('../../assets/images/home/home-fashion.jpg');
}

.home-bg-skincare {
	background-image: url('../../assets/images/home/home-skincare.jpg');
}

.home-bg-makeup{
	background-image: url('../../assets/images/home/home-makeup.jpg');
}

.home-bg-artculture {
	background-image: url('../../assets/images/home/home-artculture.jpg');
}

.home-bg-fitness {
	background-image: url('../../assets/images/home/home-fitness.jpg');
}
