.dialog-container {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
	position: fixed; 
	left: 50%;
	top: 50%;
  transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
}

.dialog-block {
	margin: 51.5px 61.5px 51.5px 61.5px;
	width: 694px;
  height: 342px;
  box-shadow: 5px 6px 30px 6px rgba(0, 154, 255, 0.05), -5px -6px 30px 6px rgba(0, 154, 255, 0.05);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
}

.dialog-shadow {
	/*position: fixed;*/
	/*top: 50%; left: 50%;*/
	/*box-shadow: 0 0 0 50vmax  rgba(0,0,0,.3);*/
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
}

.dialog-back {
	position: absolute;
	top: 0;
	left: 0;
}

.dialog-close {
	position: absolute;
	top: 0;
	right: 0;
}