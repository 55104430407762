.label {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-bottom: 5px;
}

.select {
	padding-left: 16px;
	border-radius: 8px;
	border-width: 1px;
	font-size: 16px;
}

.select-color_white {
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(0, 0, 0);
}

.select-color_gray {
	border: 0px;
	background-color: rgb(235, 235, 235);
}

.select-color_red {
	border: 1px solid red;
	background-color: red;
}