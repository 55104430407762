.published-card-collab-requirements {
	width: 365px;
  height: 47px;
}

.published-card-collab-requirements-link {
	margin-right: 9px;
}

.published-card-collab-card-cantainer {
	margin-top: 29px;
	width: 406px;
	height: 527px;
}

.published-card-collab-card-img {
	width: 406px;
  height: 272px;
  overflow: visible;
  opacity: 0.8;
}

.published-card-collab-card-block {
	width: 406px;
  height: 255px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: var(--white, #ffffff);
  overflow: auto;
  border-radius: 0px 0px 15px 15px;
}

.published-card-collab-card-context {
	font-size: 20px;
  color: #000;
}

.published-card-collab-card-num {
	width: 200px;
  margin-right: 41px;
  font-size: 48px;
  font-weight: 699;
}

.published-card-collab-card-label {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 20px;
}