.brand-collab-list-container {
  width: 1069px;
  margin: 66px 0px 0 53px;
}

.brand-collab-list-filter {
  width: 197px;
  padding-right: 30px;
  margin-top: 37px;
}

.brand-collab-list {
  margin-top: 38px;
}

.brand-collab-list-item {
  margin-bottom: 20px;
}

.btn-addnewcollab {
  height: 35px !important;
}

.btn-addnewcollab-label {
  font-size: 20px !important;
}

.brand-collab-list-bottom {
  margin: 29px 0 20px;
}

.btn-loadmore-collablist {
  width: 196px;
}
