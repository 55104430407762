.collab-card {
  width: 842px;
  min-height: 195px;
}

.collab-card-avatar {
  width: 158px;
  height: 151px;
  margin-left: 23px;
}

.collab-card-content {
  margin: 15px 17px 9px;
}

.collab-card-name {
  font-size: 28px;
  color: #000;
  font-weight: 699;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.collab-card-statusbtn {
  height: 29px !important;
  margin-top: 5px !important;
}

.collab-card-quota {
  margin-bottom: 15px;
	margin-top: 15px;
}

.collab-card-timeline {
  margin-right: 43px;
}

.collab-card-avatarlist {
  height: 38px;
  margin-top: 10px;
}

.collab-card-viewdetails {
  margin-top: 10px;
}

.collab-card-viewdetails-label {
  font-size: 14px;
  color: #696969;
}

.collab-card-viewdetails-icon {
  width: 15px !important;
  height: 15px !important;
  color: #696969;
}

.collab-card-right-empty {
  width: 256px;
}

.collab-card-statistics {
  width: 331px;
  padding: 7px 15px;
  line-height: 1.1;
}

.collab-card-statistics-card {
  width: 157px;
  height: 86px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.collab-card-platform {
  margin: 5px 0 3px;
  font-size: 12px;
  font-weight: 599;
}

.collab-card-statistics-title {
  font-size: 24px;
  color: #00cc89;
  font-weight: 800;
}

.collab-card-statistics-subtitle {
  font-size: 18px;
  font-weight: 800;
}

.collab-card-platform-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.btn-viewapplications {
  width: 149px;
  height: 24px;
  font-size: 21px;
}

.collab-card-appliedpeople {
  font-size: 16px;
  color: #000;
  font-weight: 499;
  margin-top: 14px;
}

.collab-card-delete-btn {
  margin: 10px 0 20px 0 !important;
  width: 230px !important;
  font-size: 20px !important;
}

.collab-card-delete-btn-group{
  width: 350px;
}

.collab-card-delete-dialog-font {
  font-weight: 699;
  text-align: center;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.collab-card-delete-dialog-btn {
  width: 125px;
	height: 36px;
	font-size: 16px !important;
	margin-top: 50px !important;
}