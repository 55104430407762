.panel {
  width: 341px;
  border-radius: 8px;
}

.panel-border {
  height: 37.5px;
}

.panel-top {
  border-radius: 8px 8px 0 0;
}

.panel-bottom {
  border-radius: 0 0 8px 8px;
}

.panel-title {
  padding: 3px 0 0 9px;
  font-size: 20px;
  font-weight: 699;
  line-height: 1.5;
  color: #000;
}
