.signin-page-wrap {
  width: 100vw;
  height: 100vh;
}

.signin-page {
  margin: 28px 55px;
  left: 0;
  right: 0;
  flex-grow: 1;
}

.signin-form {
  height: 713px;
  margin-top: 50px;
  box-shadow: 5px 6px 30px 6px rgba(0, 154, 255, 0.05), -5px -6px 30px 6px rgba(0, 154, 255, 0.05);
}

.signin-form_left {
  left: 3;
  right: 0;
}

.signin-form_right {
  left: 0;
  right: 3;
}

.signin-image-wrap {
  top: 46px;
}

.signin-image-wrap_left {
  right: 118px;
}

.signin-image-wrap_right {
  left: 118px;
}

.sign-logo {
  font-size: 48px;
  font-weight: 400;
  color: #000;
}

.image-title {
  margin-top: 139px;
  font-size: 40px;
  color: #fff;
}

.image-bottom {
  margin-bottom: 126px;
}

.image-shadow {
  background-color: #454545;
  opacity: 0.34;
}

.sign-btn {
  width: 196px !important;
  height: 50px !important;
  margin-bottom: 16px !important;
}

.sign-btn-font {
  font-size: 20px !important;
}
