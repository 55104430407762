.influencer-container {
	margin-left: 30px;
}

.influencer-nav-logo {
  cursor: pointer;
	font-size: 28px;
	color: rgba(0, 0, 0, 0.87);
	margin-left: 40px;
}

.influencer-nav-item {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color:  #aaaaaa;
  font-size: 20px;
  cursor: pointer;
}

.influencer-nav-item_selected {
	font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
	font-size: 20px;
	font-weight: 699;
}

.influencer-nav-chat-bubble {
	margin-right: 31px;
}

.influencer-nav-notifications-rounded {
	margin-right: 40px;
}

.influencer-nav-credits {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.3;
  text-align: right;
}

.influencer-nav-avatar {
	height: 42px;
	width: 42px;
	margin-left: 28px;
}

.influencer-nav-avatar-bubble {
  width: 187px;
  height: 157px;
  top: 50px;
  right: 0px;
}

.influencer-nav-avatar-bubble-text {
	margin-bottom: 5px;
}

.influencer-nav-msg-bubble {
  width: 300px;
  /*height: 157px;*/
  top: 50px;
  right: 150px;
}

.influencer-nav-msg-bubble-text {
	color: #999999;
}