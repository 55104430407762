.change-pwd-container {
	margin-top: 41px;
}

.change-pwd-btn-update {
	font-size: 20px !important;
	margin-top: 26px !important;
	margin-bottom: 44px !important;
	height: 42px;
	width: 118px;
}