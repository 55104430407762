.upload-image-container {
	width: 120px;
  height: 120px;
	border-radius: 5px;
  border: 2px dashed #999999;
	position: relative;
}

.upload-image-remove {
	border: 1px solid black;
	position: absolute;
	top: -17px;
	right: -15px;
}

.upload-image-input {
	width: 100%;
	height: 100%;
}

.upload-image-img {
	width: 120px;
  height: 120px;
}

img[src=""],img:not([src]){
	opacity:0;
}
