.section-title {
  height: 28px;
  background-color: #f5f5f5;
}

.section-title-title {
  margin-left: 7px;
  font-size: 24px;
  color: #000;
  font-weight: 699;
}

.section-title-icon {
  width: 25px;
  height: 25px;
}
