.stepper {
  height: 141px;
}

.stepper-label {
  width: 222px;
  height: 70px;
  font-size: 36px;
  color: #009aff;
  font-weight: 699;
  word-break: keep-all;
  white-space: nowrap;
}

.stepper-step {
  width: 222px;
  height: 25px;
  border-radius: 40px;
}

.stepper-step_selected {
  background-color: #009aff;
}

.stepper-step_unselected {
  background-color: #d7eef0;
}

.stepper-steps {
  margin-top: 53px;
}

.stepper-steps-step {
  margin: 0 10px;
  font-size: 24px;
  color: #999;
  font-weight: 699;
}

.stepper-steps-icon {
  width: 25px;
  height: 25px;
}
