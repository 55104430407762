.switchtab-title {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 20px;
	margin-bottom: 5px;
}

.switchtab-summary {
	font-weight: 199;
  font-family: ".SFNSText-Light", "SFProText-Light", "SFUIText-Light", ".SFUIText-Light", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #696969;
  font-size: 16px;
}