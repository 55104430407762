.confirmoffer-dialog {
  width: 758px;
  height: 495px;
  text-align: center;
}

.btn-confirmoffer {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.confirmoffer-title {
  width: 427px;
  font-size: 36px;
  color: #000;
  font-weight: 699;
}

.confirmoffer-subtitle {
  margin: 40px;
  font-size: 24px;
  color: #000;
  font-weight: 599;
}

.confirmoffer-underline {
  font-weight: 599;
  text-decoration: underline;
}

.confirmoffer-avatar {
  width: 62px;
  height: 62px;
  margin: 0 10px;
}

.confirmoffer-warning {
  margin-bottom: 40px;
  font-size: 20px;
  color: #ff5252;
}
