.nexttime-dialog {
  width: 758px;
  height: 495px;
}

.nexttime-dialog-title {
  margin: 87px 0 32px;
}

.btn-nexttime {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.nexttime-title {
  font-size: 24px;
  color: #000;
  font-weight: 499;
}

.nexttime-underline {
  text-decoration: underline;
}

.nexttime-avatar {
  width: 62px;
  height: 62px;
  margin: 0 10px;
}
