.influencer-product-list-filter {
	margin-top: 58px;
  width: 197px;
	height: 850px;
  padding: 0 30px 30px 30px;
}

.influencer-product-list-content {
	margin-top: 82px;
	margin-left: 109px;
  height: 100vh;
}

.influencer-product-list {
  margin: 60px 63px 13px;
}

.influencer-product-list-item {
  margin: 0 15px 47px;
}

.influencer-product-card-list {
	margin-top: 56px;
  width: 972px;
}

.influencer-product-card-listitem {
  margin-bottom: 101px;
	margin-right: 30px;
}

.influencer-product-card-quota {
  margin-top: 10px;
}

.influencer-product-list-bottom {
  margin-bottom: 22px !important;
}