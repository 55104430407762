.language-container {
	margin-right: 119px;
}

.language-text {
	margin-bottom: 36px;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 20px;
}

.language-type {
	margin-left: 9px;
  font-weight: 799;
  font-family: ".SFNSText-Heavy", "SFProText-Heavy", ".SFUIText-Heavy", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
  text-decoration: underline;
}

.language-btn-save {
	height: 42px;
	width: 118px;
	font-size: 20px !important;
	margin-top: 309px !important;
	margin-bottom: 34px !important;
}