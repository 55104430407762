.start-comp-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-bottom: 35px;
  text-align: left;
}

.start-comp-textarea {
	box-sizing: border-box;
  width: 1333px;
  height: 318px;
  overflow: visible;
  border-radius: 10px;
  border: 2px solid #222;
	padding: 28px 26px 28px 26px;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 24px;
  text-align: left;
}

.start-comp-btn {
	width: 175px;
	height: 51px;
	font-size: 18px !important;
	margin-top: 31px !important;
}