.influencer-details {
  width: 100vw;
}

.content-influencerdetails {
  width: 737px;
  height: 960px;
  padding: 16px 19px;
}

.panel-influencerdetails {
  margin-bottom: 18px;
}

.follower-list {
  width: 319px;
  height: 216px;
  margin: 7px 11px;
}

.brand-list {
  width: 341px;
  height: 510px;
  padding: 5px 0;
  margin: 7px 11px;
}

.interest-list {
  width: 321px;
  height: 206px;
  padding: 11px 10px;
}

.collab-chart-area {
  width: 341px;
  height: 535px;
}

.comment-list {
  width: 392px;
  height: 828px;
  padding: 8px 15px;
}

.comment-panel {
  width: 422px;
}

.influencer-details-comment-title {
  padding: 3px 7px 0 9px;
  font-size: 20px;
  font-weight: 699;
  line-height: 1.5;
  color: #000;
}

.influencer-details-comment-list {
  margin: 0 9px 0 9px !important;
  width: 355px !important;
}