.published-comp-collab-requirements {
	width: 365px;
  height: 47px;
}

.published-comp-collab-requirements-link {
	margin-right: 9px;
}

.published-comp-collab-card-cantainer {
	margin-top: 29px;
	width: 406px;
	height: 527px;
}

.published-comp-collab-card-img {
	width: 406px;
  height: 272px;
  overflow: visible;
  opacity: 0.8;
}

.published-comp-collab-card-block {
	width: 406px;
  height: 255px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: var(--white, #ffffff);
  overflow: auto;
  border-radius: 0px 0px 15px 15px;
}

.published-comp-collab-card-context {
	width: 270px;
	margin-bottom: 20px;
}

.published-comp-collab-card-num {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 48px;
}

.published-comp-collab-card-label {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 20px;
}

.published-card-item {
	margin-top: 30px;
	margin-left: 35px;
}

.published-comp-comments-container {
	margin-top: 69px;
}

.published-comp-comments-block {
	background-color: var(--Light_Grey, #f5f5f5);
	width: 1337px;
  height: 28px;
	margin-bottom: 29px;
}

.published-comp-comments {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.published-comp-arrow-down {
	height: 40px !important;
	width: 40px !important;
}
