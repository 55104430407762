.quota-platform {
  width: 20px;
  height: 20px;
}

.quota-count {
  font-size: 20px;
  color: #696969;
  margin: 0 9px 0 6px;
}
