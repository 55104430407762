.brand-logo {
  font-size: 65px;
  line-height: 1.3;
  color: #696969;
}

.brand-nav-prefix {
  margin-bottom: 36px;
}

.manager-avatar {
  width: 60px;
  height: 60px;
  margin-top: 43px;
}

.manager-name {
  margin: 3px 0;
  font-size: 20px;
  font-weight: 699;
  line-height: 1.1;
  color: #000;
}

.manager-position {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 199;
  font-style: italic;
  line-height: 1.1;
  color: #000;
}

.manager-brandname {
  font-size: 14px;
  font-weight: 499;
  line-height: 1.1;
  color: #000;
}

.brand-nav-item {
  width: 198px;
  height: 50px;
  padding-left: 40px;
  font-size: 14px;
  font-weight: 499;
  color: #696969;
  opacity: 0.5;
}

.brand-nav-item:hover {
  opacity: 1;
}

.brand-nav-item_selected {
  width: 198px;
  height: 50px;
  padding-left: 40px;
  color: #fff;
  background-color: #8657ff;
  border-radius: 0 22px 22px 0;
}

.brand-container {
  /*width: calc(100vw - 278px);*/
  height: 100vh;
  overflow: scroll;
}

.brand-container_light {
  background-color: #faf5ff;
}

.brand-container_dark {
  background-color: #fff;
}
