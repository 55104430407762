
.setting-info {
	margin-top: 27px;
	margin-bottom: 18px;
	width: 1112px;
	height: 415px;
	overflow-y: scroll;
}

.setting-verify {
	margin-top: 40px;
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--token-ae50998a-6462-4efb-a4e1-434b12cc60d5, #8657ff) /* {"name":"purple"} */;
  font-size: 16px;
  text-decoration: underline;
}

.setting-text {
	width: 495px;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}

.setting-update {
	font-size: 20px !important;
	margin-bottom: 31px !important;
	height: 42px;
	width: 118px;
}

.setting-delete {
	margin-right: 39px;
  font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--token-ae50998a-6462-4efb-a4e1-434b12cc60d5, #8657ff) /* {"name":"purple"} */;
  font-size: 16px;
  text-decoration: underline;
}

.setting-info-upload{
  margin-bottom: 25px;
}

.setting-upload {
  margin-top: 50px;
}

.setting-upload-font {
  /*margin-top: 10px;*/
}

.setting-info-birth-date-font {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}