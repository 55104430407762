.overview-card {
  margin-left: 15px;
}

.overview-card-avatar {
  width: 40px;
  height: 40px;
  margin-left: 25px;
}

.overview-card-title {
  font-size: 18px;
  color: #000;
  font-weight: 699;
}

.overview-card-subtitle {
  font-size: 12px;
  color: #696969;
  font-weight: 499;
}

.overview-card-status {
  font-size: 12px;
  font-weight: 699;
}

.overview-card-suffix {
  margin-right: 50px;
  font-size: 16px;
  color: #696969;
  font-weight: 699;
}
