.brandcollabrc-title-container {
	margin-bottom: 33px;
}

.brandcollabrc-title {
  margin: 5px;
  font-size: 36px;
  color: #000;
  font-weight: 699;
}

.brandcollabrc-subtitle {
	font-size: 36px;
	color: #000;
  font-weight: 599;
}

.brandcollabrc-input {
  width: 762px !important;
  height: 73px !important;
  border: 2px solid #000;
}

.btn-brandcollabrc {
  width: 175px !important;
  height: 50px !important;
  margin: 48px 0 99px !important;
  font-size: 20px !important;
}

.brandcollabrc-link-container {
	margin-top: 101px;
}

.brandcollabrc-link {
	margin-bottom: 20px;
  font-size: 24px;
  color: #000;
  font-weight: 699;
  width: 765px;
  height: 49px;
  background-color: var(--Light_Purple, #faf5ff);
}

.brandcollabrc-dialog {
	font-size: 40px;
}