.interest-wrap {
  width: 640px;
  margin-right: 100px;
}

.interest-btn {
  width: 392px !important;
  height: 50px !important;
  margin-bottom: 55px !important;
  font-size: 20px !important;
}

.interest-submit-btn {
  width: 392px !important;
  height: 50px !important;
  margin-bottom: 55px !important;
  font-size: 20px !important;
}

.signup-right-title {
  margin-top: 49px;
  font-size: 48px;
  color: #000;
}

.signup-right-subtitle {
  margin-top: 6px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
}

.signup-interest-list {
  margin-top: 44px;
}
