.card_default {
  border-radius: 5px;
}

.card-shadow_default {
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
}

.card-top_default {
  border-radius: 5px 5px 0 0;
}

.card-bottom_default {
  border-radius: 5px;
}

.card-title_default {
  font-size: 12px;
  color: #696969;
}

.card-subtitle_default {
  font-size: 14px;
  font-weight: 699;
  color: #000;
}
