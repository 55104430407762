.signup-title {
	width: 808px;
	text-align: center;
	margin-top: 19px;
	font-weight: 699;
	color: #000000;
  font-size: 36px;
	font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
}

.signup-subtitle {
  font-family: "";
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
}

.signup-upload {
  margin-top: 50px;
}

.signup-upload-font {
  /*margin-top: 10px;*/
}

.btn-verify {
	width: 100px;
	height: 51px;
}

.ssn-text {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
	font-size: 16px;
	margin-top: 20px;
}

.text {
	float: right;
	margin-top: 36px;
	margin-bottom: 13px;
	width: 669px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #aaaaaa;
  font-size: 16px;
  text-align: center;
}

.text-blue {
  color: #009aff;
  text-decoration: underline;
}

.btn-next-step{
	width: 456px;
	height: 47px;
	font-size: 20px !important;
	margin-bottom: 15px !important	;
}

.signup-birth-date-font {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}