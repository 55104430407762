.follower-card {
  width: 107px;
  height: 54px;
  padding: 24px;
}

.follower-card-title {
  margin-bottom: 14px;
  font-size: 12px;
  font-weight: 599;
  color: #000;
}

.follower-card-subtitle {
  font-size: 24px;
  font-weight: 800;
  color: #000;
}
