.help-text {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 24px;
	margin-top: 88px;
	margin-bottom: 25px;
}

.help-email {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 24px;
	text-decoration: underline;
}

.help-btn-ok {
	width: 125px;
	height: 36px;
	margin-top: 40px !important;
	font-size: 16px !important;
}