@import '../../../../../../style/interest-bg.css';

.social-acct-tab-container {
	margin-bottom: 44px;
}

.social-acct-card-container {
	width: 1127px;
	margin-top: 24px
}

.social-acct-interests{
	margin-top: 19px;
	margin-left: 33px;
	margin-right: 63px;
}

.social-acct-interests-text {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}