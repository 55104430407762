.folded-textarea-title {
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 699;
  color: #000;
}

.folded-textarea-text {
  width: 791px;
  margin-bottom: 35px;
  font-size: 20px;
  color: #000;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.folded-textarea-details {
  font-weight: 199;
}

.folded-textarea-folded {
  height: 150px;
  overflow: hidden;
}

.folded-textarea-all {
  height: 150px;
  overflow: scroll;
}

.folded-textarea-readmore {
  font-size: 16px;
}

.folded-textarea-icon {
  width: 18px;
  height: 18px;
}
