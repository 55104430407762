.approvedraft-dialog {
  width: 758px;
  height: 495px;
}

.btn-approvedraft {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.approvedraft-title {
  margin: 44px 0 28px;
  font-size: 36px;
  color: #000;
  font-weight: 699;
  text-align: center;
}

.approvedraft-subtitle {
  font-size: 24px;
  color: #000;
  font-weight: 599;
}

.approvedraft-underline {
  text-decoration: underline;
}

.approvedraft-avatar {
  width: 42px;
  height: 42px;
  margin: 0 10px 0 30px;
}
