.navigation {
  width: 278px;
  height: 100vh;
}

.navigation_light {
  background-color: #fff;
}

.navigation_dark {
  background-color: #faf5ff;
}
