.brand-inbox-container {
	margin-left: 19px;
}

.brand-inbox-avatar {
	margin-bottom: 15px;
  width: 42px;
  height: 42px;
  box-shadow: 0px 1px 3px 0px var(--Grey, #999999) /* {"name":"Grey"} */;
}

.brand-inbox-back {
	margin-top: 20px;
}

.brand-inbox-message {
  width: 600px !important;
}

.brand-inbox-avatar-container {
	margin-left: 20px;
}

.brand-inbox-avatar {
	width: 158px;
  height: 158px;
}

.brand-inbox-avatar-name {
  white-space: nowrap;
	margin-top: 16px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 20px;
}