.chat-bubble-cantainer {
  width: 50px;
  height: 50px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 25px;
}

.chat-bubble-round {
	position: relative;
	left: 14px;
	top: 8px;
  width: 10px;
  height: 10px;
  background-color: #ff3c00;
  border-radius: 50%;
}
