.fits-container {
	width: 125px;
	height: 36px;
	font-size: 16px;
	border-radius: 5px;
	margin-right: 14px;
	margin-bottom: 10px;
}

.fits-color_white {
	color: #000 !important;
	background-color: #fff !important;
	border: 1px solid black !important;
}

.fits-color_black {
	color: #fff !important;
	background-color: black !important;
	border: 1px solid black !important;
}

.fits-color_white:active {
	color: #000 !important;
	background-color: white !important;
	border: 1px solid black !important;
}