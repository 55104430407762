.text-content-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-bottom: 13px;
}

.text-content-info {
	width: 791px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.5;
}

.text-content-info-readmore {
	margin-top: 14px;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Medium_Grey, #696969);
  font-size: 16px;
}

.text-content-info-readmore-icon {
	margin-top: 14px;
	margin-right: 3px;
}