.phone-container {
	margin-right: 66px;
}

.phone-btn-confirm {
	margin-top: 28px !important;
	margin-bottom: 225px !important;
	font-size: 20px !important;
	height: 42px;
	width: 118px;
}

.phone-btn-verify {
	width: 130px;
	height: 56px;
	font-size: 20px !important;
	margin-top: 21px !important;
	margin-left: 28px !important;
}