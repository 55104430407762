.influencer-card {
  width: 391px;
  height: 237px;
  padding: 11px;
}

.influencer-card-top {
  width: 391px;
}

.influencer-card-avatar {
  width: 124px;
  height: 124px;
}

.influencer-card-basic {
  width: 270px;
  margin-left: -3px;
  height: 124px;
}

.influencer-card-title {
  font-size: 24px;
  color: #000;
  font-weight: 699;
  line-height: 1;
  margin: 0 0 7px 17px;
}

.influencer-card-categories {
  margin-bottom: 2px;
}

.btn-influencercard {
  /*width: 73px;*/
  height: 25px;
  margin: 0 0 8px 17px !important;
  font-size: 12px !important;
}

.unselected-platform {
  opacity: 0.34;
}

.influencer-card-platforms {
  width: 124px;
}

.influencer-card-platform {
  width: 30px;
  height: 30px;
  margin-top: 26px;
}

.influencer-card-statistics {
  width: 253px;
  font-weight: 699;
}

.influencer-card-statistics-data {
  margin: 14px 10px 0;
  font-size: 36px;
  color: #8657ff;
}

.influencer-card-statistics-label {
  font-size: 14px;
  color: #696969;
}
