.timeline-top {
  margin-bottom: 3px;
}

.timeline-end {
  font-size: 14px;
  font-weight: 499;
}

.timeline-lefttime {
  font-size: 12px;
  color: #696969;
}

.timeline-progress {
  margin-top: 5px;
}
