.brandcollabfilter-title {
  /*width: 199px;*/
  margin: 0 3px 0 42px;
  font-size: 20px;
  color: #000;
  font-weight: 699;
}

.brandcollabfilter-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.brandcollabfilter-label {
  font-size: 20px;
  color: #999;
}
