.influencer-list-filter {
  width: 197px;
  padding: 0 30px;
  margin-top: 58px;
}

.influencer-list-content {
  width: 1013px;
  height: 100vh;
  margin: 82px 0 0;
}

.influencer-list-search {
  width: 758px !important;
}

.influencer-list {
  margin: 60px 63px 13px;
}

.influencer-list-item {
  margin: 0 15px 47px;
}

.btn-loadmore-influencerlist {
  width: 196px !important;
}
