.confirmedialog-title {
  font-size: 36px;
  color: #000;
  font-weight: 699;
}

.btn-confirmedialog {
  margin: 0 33px !important;
  width: 125px !important;
  height: 36px !important;
  font-size: 16px;
}
