.product-received-comp-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-bottom: 13px;
}

.product-received-comp-search {
	width: 1030px !important;
}

.product-received-comp-search-btn {
	width: 243px !important;
}

.product-received-comp-describle {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Medium_Grey, #696969);
  font-size: 18px;
	margin-top: 13px;
	margin-bottom: 48px;
}

.draft-card-image {
	width: 406px;
  height: 272px;
  opacity: 0.8;
	margin-bottom: 25px;
}

.draft-card-btn {
	width: 154px;
	height: 40px;
	font-size: 18px !important;
	margin-bottom: 17px !important;
}

.draft-card-delete-btn {
	width: 85px;
	height: 40px;
	font-size: 18px !important;
}

.draft-card-approved {
	height: 28px;
  font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Blue, #009aff);
  font-size: 24px;
}

.draft-card-link {
	height: 28px;
  font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.draft-card-dialog-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
	margin-bottom: 40px;
}

.draft-card-dialog-subtitle {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Red, #ff5252);
  font-size: 24px;
	margin-top: 13px;
}

.draft-card-dialog-submit-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
	margin-top: 53px !important;
}

.draft-card-dialog-delete-btn-group {
	width: 316px;
	margin-top: 48px;
}

.draft-card-dialog-delete-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
}