.btn-search {
  width: 143px !important;
  height: 50px !important;
  margin-left: 9px !important;
  font-size: 20px !important;
}

.btn-search_shrink {
  width: 81px !important;
}

.search-shadowed {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2) !important;
}

.input-search {
  width: 699px !important;
  height: 50px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-search:focus {
	outline: none !important;
	border: 1px solid rgb(95, 0, 255) !important;
}
