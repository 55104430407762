.btn {
  box-sizing: border-box !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.btn:active {
  -webkit-transition: -webkit-transform 2s;
  transition: transform 2s;
}

.btn-color_transparent {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.btn-color_transparent:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.btn-color_transparent:active {
  color: #fff !important;
  background-color: #009aff !important;
}

.btn-color_blue,
.btn-color_blue_unclickable {
  color: #fff !important;
  background-color: #009aff !important;
  border: 1px solid #009aff !important;
}

.btn-color_blue:hover {
  color: #fff !important;
  background-color: #8657ff !important;
  border: 1px solid #8657ff !important;
}

.btn-color_blue:active {
  color: #fff !important;
  background-color: #70c6ff !important;
  border: 1px solid #70c6ff !important;
}

.btn-color_white {
	color: #000 !important;
	background-color: #fff !important;
	border: 1px solid black !important;
}

.btn-color_white:hover {
	color: #fff !important;
	background-color: black !important;
	border: 1px solid black !important;
}

.btn-color_white:active {
	color: #000 !important;
	background-color: white !important;
	border: 1px solid black !important;
}

.btn-color_black {
	color: #fff !important;
	background-color: black !important;
	border: 1px solid white !important;
}

.btn-color_black:active {
	color: #fff !important;
	background-color: black !important;
	border: 1px solid black !important;
}

.btn-color_pink {
  color: #fff !important;
  background-color: #ff33e7 !important;
  border: 1px solid #fff !important;
}

.btn-color_gray {
	color: #fff !important;
  background-color: #999 !important;
  border: 1px solid #999 !important;
}

.btn-color_purple,
.btn-color_purple_unclickable {
	color: #fff !important;
	background-color: #8657ff !important;
	border: 1px solid #8657ff !important;
}

.btn-color_red {
  color: #fff !important;
  background-color: red !important;
  border: 1px solid red !important;
}

.btn-color_red:hover {
  color: #fff !important;
  background-color: #8657ff !important;
  border: 1px solid #8657ff !important;
}

.btn-color_red:active {
  color: #fff !important;
  background-color: #70c6ff !important;
  border: 1px solid #009aff !important;
}

.btn-color_green {
  color: #fff !important;
  background-color: #3bd85b !important;
  border: 1px solid #3bd85b !important;
}

.btn-color_purple {
	color: #fff !important;
  background-color: #8657ff !important;
  border: 1px solid #8657ff !important;
}