.influencer-details-overview {
  width: 377px;
  height: 100vh;
}

.influencer-back {
  margin: 20px 34px;
}

.influencer-details-intro {
  margin-top: 123px;
}

.influencer-details-avatar {
  width: 120px;
  height: 120px;
}

.influencer-details-name {
  font-size: 24px;
  font-weight: 699;
  margin: 14px 0 25px;
}

.influencer-platform {
  width: 66px;
  margin: 0 8px;
  font-size: 14px;
  font-weight: 499;
  color: #696969;
  line-height: 1.5;
  margin-bottom: 34px;
}

.influencer-platform-name {
	word-break: break-all;
}

.unselected-platform {
  opacity: 0.34;
}

.influencer-platform-icon {
  width: 49px;
  height: 49px;
  margin-bottom: 8px;
}

.influencer-statistics {
  width: 377px;
  height: 294px;
  font-weight: 699;
  color: #8657ff;
}

.influencer-statistics_large {
  width: inherit;
  font-size: 64px;
}

.influencer-statistics_medium {
  width: 163px;
  font-size: 45px;
}

.influencer-statistics-label {
  font-size: 14px;
  color: #696969;
  line-height: 1.5;
}
