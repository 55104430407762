.collab-requirements-container {
  padding: 16px 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffbb00;
  border-radius: 8px;
}

.collab-requirements-block {
	height: 47px;
	width: 219px;
	background-color: white;
	border-radius: 8px;
}

.collab-requirements-img {
	height: 26px;
	width: 26px;
	margin-right: 7px;
	margin-left: 8px;
}

.collab-requirements-counter {
	margin-right: 10px;
}

.collab-requirements-amount {
  margin-right: 9px;
  font-size: 14px;
  color: #696969;
}
