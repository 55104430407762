
.flex-row {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.flex-between-start {
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.flex-between-end {
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
}

.flex-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-between-around {
  justify-content: space-between;
  align-content: space-around;
}

.flex-start-center {
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}

.flex-start-around {
  justify-content: flex-start;
  align-content: space-around;
}

.flex-start-between {
  justify-content: flex-start;
  align-content: space-between;
}

.flex-start-end {
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-center-end {
	justify-content: center;
  align-items: flex-end;
}

.flex-center-start {
  justify-content: center;
  align-items: flex-start;
}

.flex-center-between {
  justify-content: center;
  align-content: space-between;
}

.flex-around-center {
  justify-content: space-around;
  align-items: center;
}

.flex-center-end {
  justify-content: center;
  align-items: flex-end;
}

.flex-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-around {
  justify-content: space-around;
  align-content: space-around;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.flex-between {
  justify-content: space-between;
  align-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.flex-auto {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-unshrink {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-self-start {
  align-self: flex-start;
}

.flex-self-end {
  align-self: flex-end;
}

.flex-self-center {
  align-self: center;
}

.fit-content {
  width: fit-content;
  height: fit-content;
}

.width-inherit {
  width: inherit;
}

.height-inherit {
  height: inherit;
}

.inherit {
  width: inherit;
  height: inherit;
}

.scroll {
  overflow: scroll;
}

.scroll-x {
  overflow-x: scroll;
  overflow-y: hidden;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full {
  width: 100%;
  height: 100%;
}
