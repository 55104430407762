.home-container{
	margin-top: 55px;
	margin-left: 51px;
}
.home-logo {
  font-size: 165px;
  font-weight: 400;
  color: #fff;
}

.home-subtitle{
	margin-top: 9px;
  margin-bottom: 9px;
	opacity: 0.8;
  font-weight: 799;
  font-family: ".SFNSText-Heavy", "SFProText-Heavy", ".SFUIText-Heavy", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #ffffff;
  font-size: 40px;
}

.home-summary{
  background-color: rgba(255, 255, 255, 0.75);
  overflow: visible;
  border-radius: 8px;
	width: 1216px;
	height: 270px;
  opacity: 0.8;
  font-family: "";
  color: #000;
  font-size: 36px;
  padding: 15px 14px;
}

.home-continue {
  padding: 60px 0;
}

.home-continue-role {
  margin-bottom: 14px;
  font-size: 36px;
}

.home-circle{
	width: 240px;
	height: 240px;
  margin-right: 25px;
	font-size: 32px;
	background: #fff;
	border-radius: 50%;
}

.home-footer {
  float: right;
  margin-right: 80px;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #ffffff;
  font-size: 30px;
  letter-spacing: 0px;
}

.home-footer > a:link {
  color: #ffffff;
}

.home-footer > a:visited {
  color: #ffffff;
}
