.collab-influencer-list {
  width: 1037px;
  height: 410px;
  margin: 0 15px;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid #222;
}

.collab-influencer-list-step {
  width: 80px !important;
  height: 137px !important;
}

.collab-influencerlist-item {
  width: 140px;
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 10px;
  border: 4px solid #fff;
}

.collab-influencerlist-item_selected {
  border: 4px solid #ffbb00;
}

.collab-influencerlist-avatar {
  width: 89px;
  height: 89px;
  margin: 8px 0 4px;
}

.collab-influencerlist-name {
  margin-top: 4px;
  font-size: 24px;
  color: #000;
  font-weight: 699;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
}
