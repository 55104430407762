.account-tab-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-top: 32px;
	margin-bottom: 9px;
}

.account-tab-info {
	margin-top: 18px;
	margin-right: 101px;
}

.account-tab-avatar {
	width: 70px;
  height: 70px;
}

.account-tab-user-name {
	margin-left: 19px;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}

.account-tab-user-btn-primary {
	padding-left: 0px !important;
	padding-right: 0px !important;
	width: 196px;
  height: 28px;
	font-size: 16px !important;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif !important;
}

.account-tab-active {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #8ce88c;
  font-size: 16px;
}

.account-tab-not-active {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--token-4ee55ffc-86f2-4a93-a34b-c0438d184e23, #999999) /* {"name":"Grey"} */;
  font-size: 16px;
}

.account-tab-btn-remove {
	font-size: 12px !important;
	width: 150px !important;;
	height: 32px !important;;
	text-align: right !important;
	padding: 0px !important;
	float: right;
	margin-right: 87px !important;
}

.account-tab-dialog-text {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-top: 116px;
	margin-bottom: 40px;
}

.account-tab-dialog-text-red  {
	font-weight: 300;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: red;
  font-size: 24px;
  margin-bottom: 40px;
}

.account-tab-btn-dialog {
	height: 36px;
	width: 125px;
  font-size: 20px !important;
}

.account-tab-btn {
  width: 325px;
}