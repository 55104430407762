.product-card {
  width: 290px;
  height: 290px;
}

.product-card-image {
  width: 290px;
  height: 237px;
}

.product-card-content {
  width: inherit;
  padding: 0 20px 0 12px;
}
