.step {
  font-size: 24px;
  font-weight: 699;
}

.step-icon {
  width: 23px;
  height: 23px;
  margin-right: 9px;
}
