.footer-container {
	background-color: #000000;
	height: 124px;
}

.footer-logo {
	font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
  font-size: 28px;
}

.footer-text {
  color: rgba(255, 255, 255, 0.87);
  font-size: 20px;
  text-decoration: underline;
}
