.brand-setting-container {
	width: 1024px;
	padding-top: 41px;
	padding-left: 43px;
	border-radius: 8px;
  border: 1px solid var(--token-4ee55ffc-86f2-4a93-a34b-c0438d184e23, #999999) /* {"name":"Grey"} */;
}

.brand-setting-title {
  font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 20px;
	margin-bottom: 5px;
}

.brand-setting-subtitle {
  font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #696969;
  font-size: 16px;
}