.influencer-busicard {
  width: 464px;
  height: 179px;
}

.influencer-busicard-line {
  height: 157px;
  margin: 11px 0;
}

.influencer-busicard-avatar {
  width: 158px;
  height: 158px;
  margin-left: 11px;
}

.influencer-busicard-info {
  margin: 11px 9px;
}

.influencer-busicard-plain {
  width: 71px;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 699;
  color: #000;
}

.influencer-busicard-statistics-section {
  height: 88px;
  margin: 11px 0 18px;
}

.influencer-busicard-light {
  font-size: 12px;
  font-weight: 199;
  color: #8657ff;
}

.influencer-busicard-bold {
  font-size: 36px;
  font-weight: 699;
  color: #8657ff;
}

.influencer-busicard-engage {
  margin: 47px 14px 0 0;
}

.influencer-busicard-readmore-label {
  font-size: 14px;
  color: #696969;
}

.influencer-busicard-readmore-icon {
  width: 15px !important;
  height: 15px !important;
  margin: 0 1px 0 3px;
  color: #696969;
}
