.overview {
  width: 1204px;
  padding: 38px 33px 48px;
}

.overview-panels {
  margin-top: 43px;
}

.overview-panel-top {
  height: 51px;
  padding: 15px 20px 0;
}

.overview-panel-bottom {
  height: 47px;
}

.overview-list {
  width: 350px;
}

.overview-list-seeall {
  margin: 14px 11px 0;
  font-size: 14px;
  color: #fff;
  font-weight: 499;
}

.overview-list-seeall-btn {
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px;
  color: black;
}

.overview-list-seeall-font{
  font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 14px;
}

.overview-content {
  width: 350px;
  height: 680px;
}

.payment-list {}

.collaboration-list {}

.influencer-list-overview {
  margin-left: 21px;
  margin-right: 21px;
}
