.influencer-navigation-container {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.influencer-navigation-block {
	width: 1536px;
	height: 99px;
}

.influencer-navigation-children {
	margin-bottom: 100px;
}

.influencer-navigation-text {
	height: 26px;
	width: 456px;
	margin-left: 52px;
	margin-right: 580px;
}

.influencer-navigation-footer {
	/*position: relative;
  width: 100%;
  bottom:0;
	margin-top: 100px;*/
	margin-top: 100px;
}