.avatar-shape_circle {
  border-radius: 50% !important;
}

.avatar-shape_square {
  border-radius: 8px !important;
}

.avatar-shape_cubic {
  border-radius: 0px !important;
}