.account-card-container {
	margin-top: 21px;
	margin-left: 33px;
}

.account-card-title-block {
	margin-bottom: 14px;

}

.account-card-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000;
  font-size: 16px;
}

.account-card-btn-add {
	height: 33px;
	width: 68px;
	margin-right: 27px !important;
  font-size: 20px !important;
}

.account-card-info{
	font-size: 14px;
	color: rgb(153, 153, 153);
	margin-bottom: 8px;
}

.account-card-dialog-text {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-top: 116px;
	margin-bottom: 40px;
}

.account-card-btn-dialog {
	height: 36px;
	min-width: 125px;
	margin-left: 33px !important;
  font-size: 20px !important;
  border: 1px solid black !important;
}

.account-card-btn-dialog-icon {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.account-card-btn-dialog-youtube {
  height: 36px;
	min-width: 125px;
  font-size: 20px !important;
}