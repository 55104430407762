.make-collaboration-container {
	width: auto;
	height: 600px;
}

.make-collaboration-select-block {
	margin-top: 20px;
}

.make-collaboration-text {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.make-collaboration-select-text {
	margin-bottom: 30px;
}

.make-collaboration-period {
	margin-top: 65px;
}

.make-collaboration-requirements {
}

.make-collaboration-requirements-block {
	margin-top: 40px;
	width: 828px;
}

.make-collaboration-requirements-item {
	margin-left: 16px;
	margin-bottom: 26px;
}

.make-collaboration-requirements-textarea {
	margin-top: 23px;
	box-sizing: border-box;
  width: 895px;
  height: 291px;
  overflow: visible;
  border-radius: 8px;
  border: 2px solid #222;
	padding:20px;
	font-size: 20px;
  letter-spacing: 1px;
	font-weight: 199;
	color: #000000;
	white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
	font-family: ".SFNSText-Light", "SFProText-Light", "SFUIText-Light", ".SFUIText-Light", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
}

.make-collaboration-apply {
	margin-top: 70px;
}

.make-collaboration-apply-num {
  padding: 0;
  border: 0;
	margin-top: 27px;
	margin-bottom: 8px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #009aff;
  font-size: 37px;
  margin-left: 80px;
}

.make-collaboration-apply-btn-group {
	width: 290px;
	margin-top: 47px !important;
}

.make-collaboration-apply-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
}

.make-collaboration-maximum {
	margin-top: 53px;
	margin-bottom: 53px;
}

.make-collaboration-maximum-num {
  padding: 0;
  border: 0;
	margin-top: 27px;
	margin-bottom: 8px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #ff33e7;
  font-size: 37px;
  margin-left: 80px;
}

.make-collaboration-fits {
	width: 820px;
	margin-top: 32px;
}

.make-collaboration-fits-sameAsProductCategory {
	width: 264px !important;
	margin-left: 185px;
}

.make-collaboration-btn-save {
	width: 264px;
	font-size: 20px !important;
	margin: 90px 0 76px 57px !important;
}

.make-collaboration-requirements-counter-num {
  margin-right: 10px;
	padding-left: 5px !important;
	text-align: center;
}