.brandcollabselected {
  margin-top: 52px;
  font-size: 36px;
  color: #000;
  font-weight: 699;
}

.brandcollabselected-card {
  width: 438px;
  height: 293px;
  margin: 0 34px 66px;
  padding: 33px 0 84px;
  border-radius: 10px;
}

.brandcollabselected-card-left {
  border: 2px solid #222;
}

.brandcollabselected-card-right {
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
}

.brandcollabselected-title {
  margin-bottom: 49px;
}

.brandcollabselected-pricecard {
  width: 349px;
  height: 73px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.btn-brandcollabselected {
  height: 51px !important;
  font-size: 20px !important;
}

.btn-brandcollabselected-confirm {
  width: 155px !important;
}

.brandcollabselected-currency {
  margin-right: 7px;
  font-size: 28px;
}

.brandcollabselected-input {
  width: 297px !important;
  height: 73px !important;
  font-size: 24px !important;
  border: 2px solid #000;
}

.btn-brandcollabselected-send {
  width: 124px !important;
  margin-right: 23px !important;
}

.btn-brandcollabselected-negotiate {
  width: 175px !important;
}

.btn-brandcollabselected-nexttime {
  width: 272px !important;
  height: 40px !important;
  margin: 66px 0 0 194px !important;
  font-size: 18px !important;
}
