.inline-block {
	display: inline-block;
}

.loading {
  display: none;
  width: 6px;
  height: 10px;
	position: fixed;
	z-index: 99999;
	left:50%;
	top:50%;
	transform: translate(-50%,-50%);
	-webkit-transform:translate(-50%,-50%);

  animation: rectangle infinite 1s ease-in-out -0.2s;

  background-color: #000;
}

.loading:before,
.loading:after {
  position: absolute;
  width: 6px;
  height: 10px;
  content: "";
  background-color: #000;
}

.loading:before {
  left: -14px;

  animation: rectangle infinite 1s ease-in-out -0.4s;
}

.loading:after {
  right: -14px;

  animation: rectangle infinite 1s ease-in-out;
}

@keyframes rectangle {
  0%,
  80%,
  100% {
    height: 20px;
    box-shadow: 0 0 #000;
  }

  40% {
    height: 30px;
    box-shadow: 0 -20px #000;
  }
}

.cover-all {
	display: none;
	position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
	z-index: 9999;
  background-color: rgba(0,0,0,.8);
}