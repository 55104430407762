.log-out-block {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.log-out-btn {
	width: 125px;
	height: 36px;
	margin-top: 40px !important;
	font-size: 16px !important;
}