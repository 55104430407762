.inbox-messagepanel-avatar {
  margin-left: 5px;
  margin-right: 10px;
  width: 28px;
  height: 28px;
}

.inbox-messagepanel-name {
  margin: 0 5px;
  font-size: 16px;
  color: #696969;
  font-weight: 599;
}

.inbox-messagepanel-message {
  /*width: 750px;*/
  margin: 0 5px;
  font-size: 16px;
  color: #696969;
  font-weight: 599;
  word-break: break-all;
}

.inbox-messagepanel-time {
  font-size: 12px;
  color: #999;
  font-weight: 599;
}
