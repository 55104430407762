.influencer-collaboration-card {
	width: 354px;
	height: 370px;
	margin-right: 36px;
	border-radius: 5px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
}

.influencer-collaboration-card-avatar {
	width: 354px;
	height: 159px;
}

.influencer-collaboration-card-container {
	margin-left: 10px;
	margin-right: 13px;
}

.influencer-collaboration-card-name {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
	margin-top: 11px;
	margin-bottom: 16px;
}

.influencer-collaboration-card-applied {
	width: 86px;
	min-height: 30px;
	font-size: 16px !important;
}

.influencer-collaboration-card-pay {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 16px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.influencer-collaboration-card-end-date {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 16px;
	margin-bottom: 13px;
}

.influencer-collaboration-viewdetails-label {
  font-size: 14px;
  color: #696969;
	margin-bottom: 15px;
}

.influencer-collaboration-viewdetails-icon{
  width: 15px !important;
  height: 15px !important;
  color: #696969;
	margin-bottom: 10px;
}

.influencer-collaboration-card-block {
	height: 50px;
}