.brand-collab-details {
  margin: 65px 66px 0 92px;
}

.brand-collab-details-top {
  margin: 17px 0 24px;
  height: 679px;
}

.brand-collab-details-image {
  width: 791px;
  margin-right: 135px;
  border-radius: 8px !important;
}

.brand-collab-details-quota {
  margin-top: 14px;
}

.brand-collab-details-quota-inner {
  width: 365px;
  height: 47px;
}

.brand-collab-details-line {
  margin: 7px 0 66px;
  font-size: 20px;
  color: #000;
}

.brand-collab-details-line-item {
  margin-right: 44px;
}

.brand-collab-details-content{
  margin-bottom: 29px;
}

.btn-brandcollabdetails-editproduct {
	width: 196px;
	height: 50px;
	font-size: 20px !important;
}

.btn-brandcollabdetails-makecollab {
  width: 406px;
  height: 50px;
}

.btn-brandcollabdetails-makecollab-label {
  font-size: 20px;
}

.footer-brandcollabdetails {
  margin-top: 172px;
}

.brand-collab-details-influencercard {
  margin: 85px 0 66px;
}

.brand-collab-details-stepcontent {
  min-height: 667px;
}
