.login-block {
	margin-left: 195px
}

.signin-title {
	margin-top: 49px;
	margin-bottom: 13px;
	color: #000000;
  font-size: 48px;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
}

.signin-subtitle {
	margin-bottom: 49px;
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
}


.input-email:focus {
	outline:none;
	border: 2px solid rgb(0, 153, 255),
}

.input-email {
  margin-bottom: 12px;
}

.input-password {
	margin-bottom: 13px;
}

.input-password:focus {
	outline:none;
	border: 2px solid rgb(0, 153, 255)
}

input::placeholder {
	color: rgb(170, 170, 170);
}

.forgot-text{
	margin-bottom: 168px;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #009aff;
  font-size: 16px;
  text-decoration: underline;
}

.btn-signin {
	width: 473px;
	font-size: 20px !important;
}

.singin-dialog-font {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
}

.remember-me-container {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 10px 0;
	font-size: 14px;
  }
  
  .password-toggle {
	cursor: pointer;
	color: #666;
	font-size: 12px;
	padding: 0 8px;
  }
  
  .password-toggle:hover {
	color: #333;
  }  