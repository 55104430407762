.filter-check-box-label {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
	margin-bottom: 12px;
}

.filter-check-box-block {
	width: 197px;
	border: 1px solid #999999;
	border-radius: 3px;
}

.filter-check-box-block-scroll {
	overflow-y: scroll;
	height: 167px;
	width: 197px;
	border: 1px solid #999999;
	border-radius: 3px;
}

.filter-check-box-img {
	height: 20px;
	width: 20px;
	margin-right: 6px;
}

.filter-check-box-text {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #999999;
  font-size: 14px;
}