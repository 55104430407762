.bg-common {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.border-radius-contain {
  border-radius: inherit;
}

.border-none {
  border: none !important;
}

.bg_white {
  background-color: #fff;
}

.bg_blue {
  background-color: #009aff;
}

.bg_pink {
  background-color: #ff33e7;
}

.bg_yellow {
  background-color: #ffbb00;
}

.bg_purple {
  background-color: #8657ff;
}

.bg_lightpurple {
  background-color: #faf5ff;
}

.bg_bluegreen {
  background-color: #4cb8c2;
}

.bg_gray {
  background-color: #f5f5f5
}