.influencer-inbox-back {
	margin-top: 20px;
}

.influencer-inbox-avatar-container {
	margin-left: 50px;
}

.influencer-inbox-avatar {
	width: 158px;
  height: 158px;
}

.influencer-inbox-avatar-name {
  white-space: nowrap;
	margin-top: 16px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 20px;
}