.product-received-comp-not-receiving {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-bottom: 30px;
	margin-top: 29px;
}

.product-received-comp-btn {
	height: 51px !important;
	width: 272px !important;
	font-size: 20px !important;
	padding: 0 !important;
}