.counter-container {
	height: 36px;
	width: 55px;
	border: 1px solid rgb(153, 153, 153);
	margin-right: 6px;
	border-radius: 8px;
}

.arrow-block {
	margin-left: 15px;
}

.arrow-up {
	position: relative;
}

.arrow-up::after {
	position: absolute;
	top: -6px;
	display: inline-block;
	content: " ";
	height: 5px;
	width: 5px;
	border-width: 0 1px 1px 0;
	border-color: rgb(153, 153, 153);
	border-style: solid;
	transform: rotate(225deg);
}

.arrow-down {
	position: relative;
}

.arrow-down::after {
	position: absolute;
	top: 3px;
	display: inline-block;
	content: " ";
	height: 5px;
	width: 5px;
	border-width: 0 1px 1px 0;
	border-color: rgb(153, 153, 153);
	border-style: solid;
	transform: rotate(45deg);
}