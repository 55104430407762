.product-details-container {
	padding-top: 65px;
	padding-left: 101px;
	width: 1330px;
	margin-bottom: 172px
}

.product-details-image-list {
  margin-top: 17px;
}

.product-details-image {
  margin-bottom: 24px;
  border-radius: 8px !important;
}

.product-details-image_large {
  width: 791px;
  height: 679px;
}

.product-details-image_small {
  width: 245px;
  height: 210px;
}

.product-details-sub-image {
  border-radius: 8px !important;
	margin-left: 175px;
}

.product-details-block {
	margin-left: 135px;
}

.product-details-card {
	margin-bottom: 73px;
}

.product-details-collabRequirements-container {
	width: 406px !important;
  height: 79px !important;
	margin-bottom: 16px;
}

.product-details-collabRequirements-block {
	width: 366px;
	height: 47px;
}

.product-details-pruduct-link-container {
	margin-right: 35px;
}

.product-details-pruduct-link-font {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
}

.product-details-pruduct-link {
	margin-right: 5px;
}

.product-details-info-container {
	margin-top: 66px;
	margin-bottom: 39px;
}

.product-details-your-work-container {
	background-color: var(--Light_Grey, #f5f5f5);
	width: 1337px;
  height: 28px;
	margin-bottom: 80px;
}

.product-details-your-work {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.product-details-arrow-down {
	height: 40px !important;
	width: 40px !important;
}

.product-details-quota {
  margin-top: 14px;
}

.product-details-quota-inner {
  width: 365px;
  height: 47px;
}

.product-details-bottom {
	margin-top: 110px;
}

.product-details-bottom-block {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.product-details-apply-btn {
	width: 196px;
	height: 50px;
	font-size: 20px !important;
	margin-bottom: 99px !important;
}

.product-detals-sign-dialog-btn {
  width: 125px;
	height: 36px;
	font-size: 16px !important;
	margin-top: 50px !important;
}

.product-detals-sign-dialog-font {
	text-decoration: underline;
  font-weight: 699;
  text-align: center;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.product-detals-sign-dialog-btn-group {
	width: 300px;
}