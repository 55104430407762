.label {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	font-weight: 599;
	margin-bottom: 5px;
}

.input {
	border-radius: 8px;
	border-width: 1px;
	font-size: 16px;
}

.input-padding {
	padding-left: 25px;
}

.input-icon-padding {
	padding-left: 42px;
}

.input-color_white{
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(0, 0, 0);
}

.input-color_gray {
	border: 0px;
	background-color: rgb(235, 235, 235);
}

.input-color_red {
	border: 1px solid red;
}

.input-icon {
	position: absolute;
	left: 12px;
}
