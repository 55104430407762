.bubble-container {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 12px;
	z-index: 999;
}

.bubble-title {
	height: 31px;
	font-weight: 500;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  font-size: 16px;
	margin-bottom: 9px;
}

.bubble-title_yellow {
	background-color: #ffbb00;
}

.bubble-title_purple {
	background-color: rgb(96, 0, 255);
}

.bubble-block {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #333333;
  font-size: 16px;
  text-align: right;
	margin-right: 18px;
}
