.overview-card {
  margin-left: 15px;
}

.overview-influencerlist-item {
  width: 140px;
  height: 158px;
  margin-top: 20px;
}

.overview-influencerlist-avatar {
  width: 89px;
  height: 89px;
}

.overview-influencerlist-name {
  margin-top: 4px;
  font-size: 24px;
  color: #000;
  font-weight: 699;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
}
