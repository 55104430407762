@font-face {
  font-family: 'Berkshire Swash';
  src: local('Berkshire Swash'), url('../assets/fonts/BerkshireSwash-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

.logo-font {
  font-family: 'Berkshire Swash';
  font-weight: 400;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.font_black {
  color: #000;
}

.font_grey {
  color: #696969;
}
