.collab-detals-container {
	padding-top: 65px;
	padding-left: 101px;
	width: 1330px;
	margin-bottom: 172px;
}

.collab-details-image-list {
  margin-top: 17px;
}

.collab-details-image {
  margin-bottom: 24px;
  border-radius: 8px !important;
}

.collab-details-image_large {
  width: 791px;
  height: 679px;
}

.collab-details-image_small {
  width: 245px;
  height: 210px;
}

.collab-details-sub-image {
  border-radius: 8px !important;
	margin-left: 175px;
}

.collab-details-block {
	margin-left: 135px;
}

.collab-details-card {
	margin-bottom: 73px;
}

.collab-detals-collabRequirements-container {
	width: 406px !important;
  height: 79px !important;
	margin-bottom: 16px;
}

.collab-detals-collabRequirements-block {
	width: 366px;
	height: 47px;
}

.collab-detals-pruduct-link-container {
	margin-right: 35px;
}

.collab-detals-pruduct-link-font {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
}

.collab-detals-pruduct-link {
	margin-right: 5px;
}

.collab-detals-info-container {
	margin-top: 66px;
	margin-bottom: 39px;
}

.collab-detals-your-work-container {
	background-color: var(--Light_Grey, #f5f5f5);
	width: 1337px;
  height: 28px;
	margin-bottom: 80px;
}

.collab-detals-your-work {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.collab-detals-arrow-down {
	height: 40px !important;
	width: 40px !important;
}

.collab-details-quota {
  margin-top: 14px;
}

.collab-details-quota-inner {
  width: 365px;
  height: 47px;
}

.collab-detals-bottom {
	margin-top: 110px;
}

.collab-detals-bottom-block {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.collab-detals-stepcontent {
  margin-top: 79px;
}