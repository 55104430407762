.draft-approve-comp-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
	margin-bottom: 31px;
}

.draft-approve-comp-block {
	width: 877px;
  height: auto;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  overflow: visible;
  border-radius: 10px;
}

.draft-approve-comp-input-label {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
  margin-top: 20px;
}

.draft-approve-comp-input {
	font-size: 24px;
  margin-bottom: 20px;
}

.draft-approve-comp-btn {
	width: 272px;
	height: 40px;
	font-size: 18px !important;
  margin-bottom: 20px !important;
}

.draft-approve-comp-dialog-confirm {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.draft-approve-comp-dialog-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
	margin-top: 50px !important;
}