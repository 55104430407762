.forgotpwd-email-block {
  margin-top: 105px;
}

.forgotpwd-btn-next {
  width: 143px;
  font-size: 20px !important;
  margin-top: 30px;
}

.forgotpwd-otp-title {
  margin-top: 60px;
  margin-bottom: 42px;
  width: 458px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}