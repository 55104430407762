
.brand-profile-info {
	margin-top: 27px;
	margin-bottom: 18px;
	width: 995px;
}

.brand-profile-verify {
	margin-top: 40px;
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--token-ae50998a-6462-4efb-a4e1-434b12cc60d5, #8657ff) /* {"name":"purple"} */;
  font-size: 16px;
  text-decoration: underline;
}

.brand-profile-text {
	width: 495px;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}

.brand-profile-update {
	font-size: 20px !important;
	margin-bottom: 31px !important;
	margin-top: 57px !important;
	height: 42px;
	width: 118px;
}

.brand-profile-logo {
	margin-bottom: 30px;
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}

.brand-profile-update-dialog-text {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 28px;
	margin-bottom: 64px;
}

.brand-profile-update-btn {
	width: 125px;
	height: 36px;
}