.new-message-title {
	width: 811px;
  height: 67px;
  background-color: rgba(51, 136, 255, 0.05);
  overflow: visible;
	margin-bottom: 23px;
}

.new-message-title-font {
	font-size: 24px;
  font-weight: 699;
	margin-left: 50px;
}

.new-message-subtitle {
	border-radius: 0;
	border-bottom: 2px solid #999;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	margin-bottom: 10px;
}

.new-message-text {
	margin-top: 20px;
	box-sizing: border-box;
  width: 811px;
  height: 554px;
  overflow: visible;
  border-radius: 8px;
  border: 1px solid var(--Grey, #999999);
	padding: 30px;
}

.new-message-send {
	width: 129px;
	height: 37px;
  font-size: 18px !important;
	margin-top: 37px !important;
}