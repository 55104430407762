

.interest-bg-fashion {
  background-image: url(../assets/images/interest/interest-fashion.jpeg);
}

.interest-bg-skincare {
  background-image: url(../assets/images/interest/interest-skincare.jpeg);
}

.interest-bg-beauty {
  background-image: url(../assets/images/interest/interest-beauty.jpeg);
}

.interest-bg-makeup {
  background-image: url(../assets/images/interest/interest-makeup.jpeg);
}

.interest-bg-cosmetics {
  background-image: url(../assets/images/interest/interest-cosmetics.jpeg);
}

.interest-bg-clothes {
  background-image: url(../assets/images/interest/interest-clothes.jpeg);
}

.interest-bg-art {
  background-image: url(../assets/images/interest/interest-art.jpeg);
}

.interest-bg-culture {
  background-image: url(../assets/images/interest/interest-culture.jpeg);
}

.interest-bg-health {
  background-image: url(../assets/images/interest/interest-health.jpeg);
}

.interest-bg-fitness {
  background-image: url(../assets/images/interest/interest-fitness.jpeg);
}

.interest-bg-nutrition {
  background-image: url(../assets/images/interest/interest-nutrition.jpeg);
}

.interest-bg-technology {
  background-image: url(../assets/images/interest/interest-technology.jpeg);
}

.interest-bg-home {
  background-image: url(../assets/images/interest/interest-home.jpeg);
}

.interest-bg-lifestyle {
  background-image: url(../assets/images/interest/interest-lifestyle.jpeg);
}

.interest-bg-wellbeing {
  background-image: url(../assets/images/interest/interest-wellbeing.jpeg);
}
