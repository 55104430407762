.bg-sign-0 {
  background-image: url('../../assets/images/bg/bg-sign-0.webp');
}

.bg-sign-1 {
  background-image: url('../../assets/images/bg/bg-sign-1.webp');
}

.bg-sign-2 {
  background-image: url('../../assets/images/bg/bg-sign-2.webp');
}

.bg-sign-3 {
  background-image: url('../../assets/images/bg/bg-sign-3.jpeg');
}

.bg-sign-4 {
  background-image: url('../../assets/images/bg/bg-sign-4.webp');
}
