.select-dialog {
  width: 758px;
  height: 631px;
}

.select-dialog-title {
  margin: 27px;
}

.btn-select {
  width: 272px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.select-title {
  margin-top: 40px;
  font-size: 24px;
  color: #000;
  font-weight: 599;
}

.select-underline {
  text-decoration: underline;
}

.select-avatar {
  width: 40px;
  height: 40px;
  margin: 0 10px 0 30px;
}
