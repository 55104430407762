.brand-product-list-container {
  width: calc(100vw - 278px);
}

.brand-product-list-top {
  margin: 82px 0 43px;
}

.brand-product-list {
  width: 972px;
}

.brand-product-listitem {
  margin: 0 17px;
}

.brand-product-list-bottom {
  margin-bottom: 22px !important;
}

.btn-loadmore-productlist {
	width: 196px;
}

.input-productsearch {
  width: 558px !important;
}

.btn-productsearch {
  width: 81px !important;
  margin: 0 38px 0 27px !important;
}

.btn-productadd {
  width: 210px;
  height: 50px;
  font-size: 20px !important;
  flex-shrink: 0;
}

.link-editproduct {
  height: 40px;
  font-size: 16px;
  font-weight: 499;
	text-decoration: underline;
}

.btn-makecollab {
	float: right;
  width: 290px;
  height: 40px;
  margin: 12px 0 30px !important;
}

.btn-makecollab-label {
  font-size: 20px !important;
}
