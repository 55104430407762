.follow-card-container{
	width: 325px;
  height: 349px;
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.13), -1px 0px 3px 0px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 8px;
}

.follow-card-avatar-container {
	position: relative;
}

.follow-card-avatar {
	width: 325px;
	height: 133px;
}

.follow-card-inner-avatar {
  width: 66px;
  height: 66px;
	position: absolute;
	top: 100px
}

.follow-card-category {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-top: 59px;
	margin-bottom: 3px;
}

.follow-card-category-description {
	width: 272px;
  height: 60px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  position: relative;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Medium_Grey, #696969);
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.3;
  text-align: center;
}

.follow-card-btn {
	width: 90px;
	height: 33px;
	font-size: 20 !important;
	border-radius: 17px !important;
	margin-top: 15px !important;
}

.follow-card-btn-purple {
	color: #fff !important;
  background-color: #8657ff !important;
  border: 1px solid #8657ff !important;
}

.follow-card-btn-white {
	color:  #009aff !important;
  background-color: #fff !important;
  border: 1px solid #009aff !important;
}
