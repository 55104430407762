.avatar-list-ellipsis {
  margin: 0 5px;
  font-size: 14px;
  font-weight: 499;
  color: #696969;
}

.avatar-list-item_default {
  width: 34px;
  height: 34px;
  border: 2px #fff solid;
  border-radius: 50%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  font-size: 10px;
  font-weight: 699;
  color: #999;
}
