.selected-comp-container {
	width: 931px;
  height: 410px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  overflow: visible;
  border-radius: 10px;
}

.selected-comp-brand-pay-offer-container {
	/*margin-left: 98px;
	margin-top: 33px;*/
	width: 349px;
	height: 326px;
}

.selected-comp-brand-pay-offer {
	margin-bottom: 11px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.selected-comp-payments {
	margin-bottom: 30px;
	width: 349px;
  height: 108px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  overflow: visible;
  border-radius: 10px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 48px;
}

.selected-comp-btn {
	width: 175px;
	height: 51px;
	font-size: 18px !important;
	margin-bottom: 24px !important;
}

.selected-comp-rate-applied-container {
	box-sizing: border-box;
  width: 297px;
  height: 216px;
  overflow: visible;
  border-radius: 10px;
  border: 2px solid #222;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
}

.selected-comp-rate-applied {
	font-size: 36px;
}

.selected-comp-rate-applied-payments {
	font-size: 64px;
}

.selected-comp-dialog-pay {
	margin-top: 56px;
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Black, #000000);
  font-size: 20px;
}

.selected-comp-dialog-confirm {
	margin-top: 42px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.selected-comp-dialog-tips {
	margin-top: 25px;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Red, #ff5252);
}

.selected-comp-dialog-btn-container {
	margin-top: 49px;
	width: 287px;
	height: auto;
}

.selected-comp-dialog-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
}