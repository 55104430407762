.influencer-collaboration-container {
	padding-left: 95px;
	padding-right: 95px;
}

.influencer-collaboration-title {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
	margin-top: 50px;
}

.influencer-collaboration-img-container {
	margin-top: 23px;
	margin-bottom: 48px;
}

.influencer-collaboration-img {
	width: 1049px;
	height: 387;
}

.influencer-collaboration-img-item {
	margin-right: 30px;
	margin-bottom: 30px;
}

.influencer-collaboration-img-name {
  font-size: 20px;
  font-weight: 600;
}

.influencer-collaboration-fiter-container {
	width: 1337px;
	/*height: 230px;*/
	padding-top: 20px;
	background-color: #fafafa;
}

.influencer-collaboration-collab-card {
	width: 1170px;
}

.influencer-collaboration-collab-card-item {
	margin-top: 92px;
	margin-bottom: 92px;
}