.inbox-listitem{
  width: 398px;
  padding: 9px 9 px 14px 14px;
  border: 1px solid #999;
  border-radius: 8px;
}

.inbox-listitem-avatar {
  margin: 4px 10px 4px 0px;
  width: 28px;
  height: 28px;
}

.inbox-listitem-name {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.inbox-listitem-time {
  font-size: 16px;
  color: #696969;
  font-weight: 599;
}

.inbox-listitem-content {
  overflow: hidden;
  font-size: 16px;
  color: #999;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.inbox-listitem-status {
	display: inline-block;
  height: 19px;
  margin-bottom: 14px;
  padding: 1px 5px 1px 5px;
  color: #fff;
  border-radius: 2px;
  box-sizing: border-box;
}

.inbox-listitem-status_blue {
  background-color: #009aff;
}

.inbox-listitem-status_purple {
  background-color: #8657ff;
}
