.influencer-list-filter-categories {
	margin-bottom: 45px;
}

.influencer-list-filter-platform {
	margin-bottom: 37px;
}

.influencer-list-filter-select {
	margin-top: 40px;
}

.influencer-list-filter-with {
	border: 0 !important;
}