.comment-card {
  border: 1px solid #222;
  width: 376px;
  height: 103px;
  padding: 6px 10px 13px;
  margin-bottom: 9px;
  color: #000;
  line-height: 1.2;
  border-radius: 8px;
}

.comment-username {
  font-size: 16px;
  font-weight: 699;
}

.comment-position {
  margin-left: 7px;
  font-size: 14px;
  font-weight: 199;
  font-style: italic;
}

.comment-brandname {
  font-size: 16px;
  font-weight: 599;
  font-style: italic;
}

.comment-content {
  width: 376px;
  height: 50px;
  margin: 10px 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}

.comment-date {
  font-size: 12px;
  font-weight: 199;
}
