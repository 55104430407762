.tab_default {
  font-size: 20px;
  font-weight: 599;
  margin-right: 65px;
}

.tab-selected_default {
  color: #000;
  text-decoration: underline;
}

.tab-unselected_default {
  color: #999;
}
