.absolute {
  position: absolute;
}

.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 9;
}

.z-index-3 {
  z-index: 99;
}

.z-index-4 {
  z-index: 999;
}

.z-index-5 {
  z-index: 9999;
}