.publishcomment-card{
  width: 506px;
  padding: 44px 33px 23px;
  top: 25px;
  bottom: 25px;
  background-color: #fff;
  font-size: 20px;
  color: #000;
}

.publishcomment-card-influencer {
  width: 356px;
  height: 179px;
  padding: 11px;
  font-weight: 699;
}

.publishcomment-card-avatar {
  margin-right: 15px;
  width: 158px;
  height: 158px;
}

.publishcomment-card-thanks-top {
  margin: 23px 0 65px;
}

.publishcomment-card-thanks {
  margin-bottom: 10px;
}

.publishcomment-card-field {
  width: 89px;
  margin: 7px 0 0 25px;
  font-size: 16px;
  color: #000;
  font-weight: 599;
}

.publishcomment-card-input {
  margin-top: 7px;
  width: 286px !important;
  height: 36px !important;
}

.publishcomment-card-comment {
  width: 438px;
  height: 285px;
  margin-top: 14px;
  padding: 15px;
  color: #333;
  line-height: 1.4;
  background-color: #ebebeb;
  border: #ebebeb 1px solid;
  border-radius: 8px;
}

.btn-publishcomment {
  margin: 23px 0 !important;
  width: 119px !important;
  height: 38px !important;
  font-size: 16px !important;
}
