.brandcollabstart-title {
  font-size: 24px;
  color: #000;
  font-weight: 699;
  margin: 33px 0;
}

.brandcollabstart-textarea {
  width: 1333px;
  height: 318px;
  margin-bottom: 7px;
  padding: 28px 23px 26px;
  font-size: 24px;
	border: 1px solid black;
	border-radius: 10px;
  text-align: left;
}

.btn-brandcollabstart {
  width: 196px !important;
  height: 50px !important;
  font-size: 18px !important;
  margin-top: 17px !important;
}
