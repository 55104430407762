.brandcollabfilter {
  width: 1336px;
}

.brandcollabfilter-content {
  height: 193px;
  margin: 30px 0;
  background-color: #fafafa;
  border-radius: 10px;
}
