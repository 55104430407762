.apply-dialog-container {
	height: 600px;
	width: 694px;
}

.apply-title {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-top: 20px;
	margin-bottom: 18px;
}

.apply-card {
	border-radius: 5px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
	width: 290px;
	height: 285px;
}

.apply-avatar {
	width: 290px;
	height: 240px;
}

.apply-card-style {
	margin-left: 10px;
}

.apply-card-label {
	margin-left: 10px;
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
}

.apply-message {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-top: 17px;
	margin-bottom: 8px;
}

.apply-textarea {
	width: 624px;
	height: 167px;
	box-sizing: border-box;
  overflow: visible;
  border-radius: 8px;
  border: 2px solid #222;
	padding:20px;
	font-size: 16px;
  letter-spacing: 1px;
	font-size: 20px;
  letter-spacing: 1px;
	font-weight: 199;
	color: #000000;
	white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
	font-family: ".SFNSText-Light", "SFProText-Light", "SFUIText-Light", ".SFUIText-Light", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
}

.apply-rate-block {
	margin-top: 31px;
	margin-left: 36px;
	align-self: flex-start
}

.apply-rate {
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-right: 17px;
}

.apply-comfirm-address {
	margin-left: 36px;
	margin-top: 25px;
	margin-bottom: 17px;
	align-self: flex-start;
	font-weight: 599;
  font-family: ".SFNSText-Semibold", "SFProText-Semibold", "SFUIText-Semibold", ".SFUIText-Semibold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 16px;
	margin-right: 17px;
}

.apply-address {
	border-radius: 10px;
  border: 1px solid #222;
	width: 340px;
	min-height: 85px;
	padding: 10px;
}

.apply-address-font {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
	font-weight: 510;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 1.5;
}

.apply-checkbox {
	margin-left: 36px;
	margin-top: 33px;
	margin-bottom: 32px;
	align-self: flex-start;
	width: auto;
}

.apply-input-block {
	margin-left: 36px;
	margin-bottom: 66px;
}

.apply-btn {
	width: 337px;
	margin-bottom: 42px;
}

.apply-send-btn {
	height: 36px;
	font-size: 16px !important;
}

.apply-send-cancel-btn {
	width: 124px;
	height: 36px;
	font-size: 16px !important;
}

.apply-dialog-send {
  width: 694px;
  height: 620px;
  box-shadow: 5px 6px 30px 6px rgba(0, 154, 255, 0.05), -5px -6px 30px 6px rgba(0, 154, 255, 0.05);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
}

.apply-text {
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: rgb(153, 153, 153);
  font-size: 20px;
  margin-bottom: 62px;
}