.back {
  font-size: 24px;
  font-weight: 699;
}

.back-icon {
  width: 23px;
  height: 23px;
  margin-right: 9px;
}

.forward-icon {
  width: 23px;
  height: 23px;
	margin-left: 9px;
}