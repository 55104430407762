.rate-confirm-comp-container {
	width: 760px;
  min-height: 369px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.25);
  overflow: visible;
  border-radius: 10px;
}

.rate-confirm-comp-title {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.rate-confirm-comp-link {
	font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.rate-confirm-comp-btn {
	height: 51px;
	width: 175px;
	font-size: 20px !important;
	padding: 0 !important;
}

.rate-confirm-comp-not-receiving {
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
	margin-bottom: 30px;
	margin-top: 29px;
}

.rate-confirm-comp-dialog-confirm {
	margin-top: 110px;
	font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}

.rate-confirm-comp-dialog-tips {
	margin-top: 13px;
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: var(--Red, #ff5252);
  font-size: 24px;
}

.rate-confirm-comp-dialog-btn-container {
	margin-top: 48px;
	width: 287px;
	height: auto;
}

.rate-confirm-comp-dialog-btn {
	width: 125px;
	height: 36px;
	font-size: 16px !important;
}