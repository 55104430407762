.product-details {
  margin: 65px 66px 0 92px;
}

.product-details-image-list {
  width: 1281px;
  height: 703px;
  margin-top: 17px;
}

.product-details-image {
  margin-bottom: 24px;
  border-radius: 8px !important;
}

.product-details-image_large {
  width: 791px;
  height: 679px;
}

.product-details-image_small {
  width: 245px;
  height: 210px;
  margin-left: 44px;
}

.product-details-line {
  margin: 7px 0 66px;
  font-size: 20px;
  color: #000;
}

.product-details-line-item {
  margin-right: 44px;
}

.product-details-content{
  margin-bottom: 66px;
}

.btn-productdetails-editproduct {
	width: 196px;
	height: 50px;
	font-size: 20px !important;
}

.btn-productdetails-makecollab {
  width: 406px;
  height: 50px;
  margin-left: 200px !important;
}

.btn-productdetails-makecollab-label {
  font-size: 20px;
}

.footer-productdetails {
  margin-top: 172px;
}
