.product-add-container {
	width: auto;
	height: 600px;
	padding-left: 37px;
}

.product-add-name {
	margin-top: 43px;
	margin-bottom: 46px;
}

.product-add-price {
	margin-bottom: 60px;
}

.product-add-label-text {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 24px;
}

.product-add-uplaod-block {
	margin-top: 25px;
}

.product-add-select-block {
	margin-top: 22px;
	margin-left: 30px;
}

.product-add-select-text {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
	margin-bottom: 14px;
}

.product-add-description-block {
	margin-top: 22px;
	margin-left: 30px;
}

.product-add-description-text {
	font-weight: 499;
  font-family: ".SFNSText-Medium", "SFProText-Medium", "SFUIText-Medium", ".SFUIText-Medium", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 20px;
	margin-bottom: 14px;
}

.product-add-description-textarea {
	box-sizing: border-box;
  width: 895px;
  height: 291px;
  overflow: visible;
  border-radius: 8px;
  border: 2px solid #222;
	padding:20px;
	font-size: 16px;
  letter-spacing: 1px;
	font-size: 20px;
  letter-spacing: 1px;
	font-weight: 199;
	color: #000000;
	white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
	font-family: ".SFNSText-Light", "SFProText-Light", "SFUIText-Light", ".SFUIText-Light", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
}

.product-add-btn-block {
	margin-top: 126px;
	margin-bottom: 60px;
}

.product-add-btn-save {
	width: 100px;
	height: 42px;
	font-size: 20px !important;
}

.product-add-btn-save-notify {
	width: 263px;
	height: 42px;
	font-size: 20px !important;
	margin-left: 10px !important;
}

.product-add-btn-delete {
	float: right !important;
	width: 170px;
	height: 42px;
	font-size: 20px !important;
	margin-right: 30px !important;
}

.product-add-btn-group {
  margin-top: 50px;
}

.product-add-delet-dialog {
  font-weight: 699;
  font-family: ".SFNSText-Bold", "SFProText-Bold", "SFUIText-Bold", ".SFUIText-Bold", "SF Pro Text", "-apple-system", "BlinkMacSystemFont", sans-serif;
  color: #000000;
  font-size: 36px;
}